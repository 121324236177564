<template>
  <div class="cloud-live-list">
    <AddressPosition />
    <div class="list-container">
      <!-- <div class="header">
        <span class="text">云直播</span>
      </div> -->
      <div v-if="loading || list && list.length" class="list">
        <el-row :gutter="52">
          <el-col v-for="item in loading ? 6 : list" :key="loading ? item : item.id" :span="8">
            <el-skeleton :loading="loading" animated style="width: 373px;">
              <ShadowCard
                :cover-src="item.image"
                cover-width="373px"
                cover-height="281px"
                clickable
                @click.native="toUrlPage(item.url)"
              >
                <template #content>
                  <div class="card-title">{{ item.title }}</div>
                </template>
              </ShadowCard>
              <template #template>
                <el-skeleton-item variant="image" style="width: 373px; height: 281px; margin-bottom: 40px;" />
                <div style="padding: 14px;">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div style="display: flex; align-items: center; justify-items: space-between;">
                    <el-skeleton-item variant="text" style="margin-right: 16px;" />
                    <el-skeleton-item variant="text" style="width: 30%;" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </el-col>
        </el-row>
      </div>
      <el-empty v-else-if="!loading" :image-size="200" />
    </div>
  </div>
</template>

<script>
import { getLiveBannerList } from '@/api'
import ShadowCard from '@/components/ShadowCard.vue'
import AddressPosition from '@/components/AddressPosition'
import { MINI_PROGRAM_PAGE_LIST } from '@/utils/constant'
import { webviewUrlHandler, getUrlParams } from '@/utils/url'
import imgSrc from "@/utils/bigDataIMG.js"
const bigDataIMG = imgSrc.data()

export default {
  name: 'CloudLiveList',

  components: { AddressPosition, ShadowCard },

  data() {
    return {
      // 列表
      list: [],
      loading: true,
      bigDataIMG,
      // 当前需要请求的年份. 默认值: '2023'
      year: '2023'
    }
  },

  created() {
    const { year = '2023' } = this.$route.query
    this.year = year
    this.getListData()
  },

  methods: {
    /* 获取云直播列表数据 */
    getListData() {
      const { year } = this
      this.loading = true
      return getLiveBannerList({
        // 根据当前需要请求的年份, 构造 `timeBegin` 和 `timeEnd` 入参
        timeBegin: `${year}-01-01 00:00:00`,
        timeEnd: `${year}-12-31 23:59:59`
      }).then(res => {
        const list = res.data
        this.list = list.sort((a, b) => a.seq - b.seq)
      }).catch(error => {
        this.list = []
        console.log('getListData', error)
      }).finally(() => {
        this.loading = false
      })
    },

    /* 打开url */
    toUrlPage(url = '') {
      // 先判断url是否为后台配置的小程序页面
      const miniProgramPage = MINI_PROGRAM_PAGE_LIST.find(item => url.includes(item.url))
      if(!miniProgramPage) {
        url && window.open(webviewUrlHandler(url))
      } else {
        this.$router.push({
          name: miniProgramPage.name,
          ...(url.includes('?') && {
            query: getUrlParams(url)
          })
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cloud-live-list {
  width: 1280px;
  margin: 0 auto;
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(332px - 32px * 2);
      height: 70px;
      margin: 70px 0;
      font-size: 28px;
      font-weight: 600;
      color: #fff;
      // background: linear-gradient(90deg, #c9d7ff 0%, #7f95d1 100%);
      background: linear-gradient(90deg, #9aee96 0%, #349477 100%);
      .icon {
        margin-right: 5px;
        object-fit: cover;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -31px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 32px 35px 0;
        // border-color: transparent #c9d7ff transparent transparent;
        border-color: transparent #9aee96 transparent transparent;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -31px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 0 35px 32px;
        // border-color: transparent transparent transparent #7f95d1;
        border-color: transparent transparent transparent #349477;
      }
    }
    .list {
      .el-row {
        width: 1280px;
      }
      .el-col {
        margin-bottom: 40px;
      }
      .card-title {
        height: 97px;
        line-height: 97px;
        text-align: center;
        font-size: 18px;
        color: #333;
      }
    }
  }
}
</style>